import styled from "styled-components";
import {theme} from "/helpers/theme";

export const ReferalLink = styled.section`
  margin-top: 100px;
  margin-bottom: 99px;
  
  @media (max-width: 990px) {
    margin: 36px -20px 70px
  }

  @media (max-width: 575px) {
    margin-bottom: 0
  }
`;

export const ReferalLinkWrapper = styled.div`
  min-height: 574px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
  background: #F9F9F9;

  .referal-link__left {
    position: absolute;
    top: -3px;
    left: -115px;
    bottom: 0
  }

  .referal-link__left img {
    width: 100%;
    max-width: 865px
  }

  .referal-link__left .mob {
    display: none
  }

  .referal-link__right {
    position: relative;
    z-index: 1;
    padding-top: 60px;
    padding-bottom: 20px;
    max-width: 709px;
    width: 100%
  }

  .referal-link__right p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-top: 16px
  }

  .referal-link__right-wp {
    position: relative;
    z-index: 2;
    max-width: 576px;
    margin-right: auto
  }

  .referal-link__right:before {
    content: url(/images/referal/back.svg);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0
  }

  .referal-link__link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 53px;
    max-width: 536px;
    transition: opacity .25s;
    cursor: pointer;
  }

  .referal-link__link span {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #EF6957;
    width: 89%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  @media (min-width: 991px) {
    .referal-link__link:hover {
      opacity:.8
    }
  }

  .referal-link__share {
    margin-top: 51px
  }

  .referal-link__share-wp {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 562px
  }

  .referal-link__share a {
    width: 48%;
    padding-left: 24px;
    height: 64px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 20px;
    background: #4985DF;
    color: #ffffff;
    transition: opacity .25s;
    cursor: pointer;
  }

  .referal-link__share a div {
    width: 28px;
    margin-right: 9px
  }

  .referal-link__share a:nth-of-type(2) {
    background: #5281B8
  }

  .referal-link__share a:nth-of-type(3) {
    background: #4460A0
  }

  .referal-link__share a:nth-of-type(4) {
    background: #0A93E2
  }

  @media (min-width: 991px) {
    .referal-link__share a:hover {
      opacity:.8
    }
  }

  @media (max-width: 1440px) {  
    min-height: 460px;

    .referal-link__right {
      max-width: 630px;
      padding-top: 29px
    }

    .referal-link__right:before {
      right: -150px
    }

    .referal-link__link,.referal-link__share {
      margin-top: 30px
    }

    .referal-link__left img {
      max-width: 795px
    }
  }

  @media (max-width: 1140px) {
    .referal-link__right {
      max-width:590px
    }

    .referal-link__right:before {
      right: -190px
    }

    .referal-link__left img {
      max-width: 700px
    }
  }

  @media (max-width: 990px) {
    min-height: 0;
    display: block;

    .referal-link__left {
      position: static;
      width: 100%;
      max-height: 450px;
      overflow: hidden;
      border-radius: 0 0 47% 47%
    }

    .referal-link__left img {
      max-width: none;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 0 -55px
    }

    .referal-link__right {
      max-width: none;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      margin-left: 20px;
      margin-right: 40px;
      width: 100%
    }

    .referal-link__right:before {
      display: none
    }

    .referal-link__right-wp {
      margin: 0
    }
  }

  @media (max-width: 760px) {
    .referal-link__right {
      margin:0;
      padding-left: 20px;
      padding-right: 20px
    }

    .referal-link__right-wp {
      max-width: none
    }

    .referal-link__right p {
      font-size: 14px;
      line-height: 20px
    }

    .referal-link__left {
      height: 55vw
    }

    .referal-link__link span {
      font-size: 12px;
      line-height: 15px
    }
  }

  @media (max-width: 575px) {
    .referal-link__left {
      border-radius: 0;
      height: auto
    }

    .referal-link__left img {
      object-position: initial;
      object-fit: fill;
      height: auto
    }

    .referal-link__left .mob {
      display: block
    }

    .referal-link__left .desc {
      display: none
    }

    .referal-link__right {
      padding-top: 20px;
      padding-bottom: 40px
    }

    .referal-link__right-wp {
      width: 100%
    }

    .referal-link__right p {
      margin-top: 8px
    }

    .referal-link__link {
      margin-top: 20px
    }

    .referal-link__link span {
      width: 80%
    }

    .referal-link__share {
      margin-top: 28px
    }

    .referal-link__share-wp {
      display: block
    }

    .referal-link__share h5 {
      margin-bottom: 12px
    }

    .referal-link__share a {
      height: 48px;
      font-size: 14px;
      line-height: 16px;
      margin-top: 8px;
      width: 100%;
      padding-left: 28px
    }

    .referal-link__share a div {
      margin-right: 12px
    }
  }

`;

export const ReferalLinkLeft = styled.div``;

export const ReferalLinkRight = styled.div``;

export const ReferalLinkRightWrapper = styled.div`
  
  .referal-link__form-and-button-container {
    display: flex;
    flex-direction: column;
    
    &.is-reverse {
      flex-direction: column-reverse;
      
      .referal-link__share {
        margin-top: 0;
      }
      
      .referal-link__form-container {
        margin-top: 51px;
      }
      
      @media (max-width: 1440px) {
        .referal-link__form-container {
          margin-top: 30px;
        }
      }
    }
  }
`;

export const ReferalLinkRightShare = styled.div``;

export const ReferalLinkRightShareWrapper = styled.div``;