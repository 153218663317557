import styled from "styled-components";
import {theme} from "/helpers/theme";
import {ReferalLink} from "./VacancyReferral.style";

// Banner

export const VacanciesWrapper = styled.section`
  .vacancy__response {
    padding-top: 80px;
    padding-bottom: 35px
  }
  
  &.show-vacancy-banner-in-mobile {
    display: none;
  }
  
  .vacancy__vertical-banner-wrapper {
    margin-top: 27px;
    margin-bottom: 36px;
  }

  @media (max-width: 1440px) {
    &.show-vacancy-banner-in-mobile {
      display: block;
    }
  }
  
  
`;

export const VacancyWrapper = styled.section`

  .vacancy-detail-sub-message-active {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  }
  
  .vacancy .ya-share2__popup.ya-share2__popup_mobile.ya-share2__popup_visible .ya-share2__popup-content {
    z-index: 5
  }
  
  .vacancy__main {
    margin-top: 42px
  }

  .vacancy__top .btn {
    border-radius: 24px;
    height: 29px;
    padding: 1px 17px 0;
    margin-left: 32px;
    font-size: 14px;
    line-height: 17px
  }
  
  .vacancy__top-line {
    display: -ms-flexbox;
    display: flex;
    margin-top: 32px
  }

  @media (min-width: 1441px) {
    .vacancy__main h1 {
      line-height:78px
    }
  }

  
  @media (max-width: 990px) {
    .vacancy__main {
      margin-top: 23px
    }
    
    .vacancy__top .btn {
      font-size: 12px;
      line-height: 15px;
      height: 27px;
      margin-left: 0
    }
    
    .vacancy__top-line {
      margin-top: 28px
    }
  }

  @media (max-width: 575px) {
    .vacancy__top .btn {
      margin-top: 16px;
      padding-top: 0
    }
    
    .vacancy__top-line {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin-top: 22px
    }
  }
  

  .vacancy__response {
    padding-top: 80px;
    padding-bottom: 35px
  }
  
  &.show-vacancy-banner-in-mobile {
    display: none;
  }
  
  .vacancy__vertical-banner-wrapper {
    margin-top: 27px;
    margin-bottom: 36px;
  }

  @media (max-width: 1440px) {
    &.show-vacancy-banner-in-mobile {
      display: block;
    }
  }
`;


export const VacanciesSnippetWrapper = styled.section`{
  padding-top: 20px;
  padding-bottom: 20px;

  .main-slider__slide-right {
    width: 544px;
  }


  @media (max-width: 990px) {
    padding-left: 10px;
    padding-right: 10px;

    .main-slider__slide-right {
      width: 100%;
    }
  }
}`

export const VacanciesList = styled.div``;

export const VacanciesListWrapper = styled.div`
  margin-top: 78px;
  padding-bottom: 88px;

  @media (max-width: 990px) {
    margin-top: 22px;
    padding-bottom: 39px
  }
`;

export const VacanciesListContent = styled.div`
  &.vacancy-list__content {
    margin-top: 82px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between
  }

  @media (max-width: 990px) {
    &.vacancy-list__content {
      margin-top: 33px
    }

    &.vacancy-list__content {
      display: block
    }

  }

`;

export const VacanciesListLeft = styled.div`
  &.vacancy-list__left {
    width: 69.6%;
    margin-right: 20px
  }

  @media (max-width: 990px) {
    &.vacancy-list__left {
      width: 100%;
      margin-right: 0
    }
  }
`;

export const VacanciesListLeftList = styled.div`
  a:hover {
    color: ${theme.colors.black};
  }
`;

export const VacanciesListRight = styled.div`
  position: relative;
`;

export const VacancyListPagination = styled.div`

  &.vacancy-list__pagination {
    margin-top: 63px;
    margin-left: -12px
  }

  .vacancy-list__pagination-wp {
    display: -ms-flexbox;
    display: flex
  }

  .vacancy-list__pagination-el {
    width: 36px;
    margin-left: 12px;
    margin-right: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    transition: color .25s
  }

  .vacancy-list__pagination-el.current {
    position: relative;
    color: #EF6957;
    font-weight: 600
  }

  .vacancy-list__pagination-el.current:before {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -8px;
    height: 2px;
    background: #EF6957
  }

  .vacancy-list__pagination-el svg path {
    transition: .25s
  }

  @media (min-width: 991px) {
    &.vacancy-list__pagination a.vacancy-list__pagination-el:hover {
      color:#8A8A89
    }

    &.vacancy-list__pagination a.vacancy-list__pagination-el:hover svg path {
      stroke: #EF6957
    }

    &.vacancy-list__pagination a.vacancy-list__pagination-el:active {
      color: #8A8A89;
      font-weight: 600
    }
  }

  @media (max-width: 990px) {
    &.vacancy-list__pagination {
      margin-left: -8px;
      margin-top: 49px
    }

    .vacancy-list__pagination-el {
      width: 28px;
      margin-left: 8px;
      margin-right: 8px;
      font-size: 14px;
      line-height: 24px
    }

    .vacancy-list__pagination-el.current:before {
      bottom: -3px
    }
  }

  @media (max-width: 575px) {
    &.vacancy-list__pagination .mob-n {
      display: none
    }
  }

`;

export const VacancyListPaginationWrapper = styled.div`
`;


export const VacancyLeftWrapper = styled.div`
  max-width: 1002px;
  width: 70%;
  margin-right: 20px;

  @media (max-width: 1140px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 36px
  }


  .respond-email-btn {
    color: #323232;
    font-size: 20px; }
  @media (max-width: 990px) {
    .respond-email-btn {
      font-size: 14px; }}

  .respond-email-btn a {
    color: ${theme.colors.orange}; }
  
  .respond-email-btn a.a2:hover {
    border-color: ${theme.colors.orange};
  }

  .respond-email-btn a.a2.questmod {
    font-size: 22px;
  }

  @media (max-width: 990px) {
    .respond-email-btn a.a2.questmod {
      font-size: 14px; }}
  
  .respond-email-btn a.a2.disabled-link,
  .respond-email-btn a.a2.disabled-link:hover {
    border-color: ${theme.colors.darkGray};
    color: ${theme.colors.darkGray};
    cursor: auto;
  }


  .respond-email-btn a span {
    color: #323232;
    font-weight: bold; }

  .respond-email-form {
    color: #323232;
    margin-top: 20px;
    font-size: 18px; }

  .respond-email-form a {
    font-size: 18px; }
  
  .respond-email-form .a2 {
    font-size: 16px; }


  .respond-email-form a span {
    color: #323232;
    font-weight: bold; }

  .respond-email-form a:hover {
    color: #EF6957;
  }
  @media (max-width: 990px) {
    .respond-email-form {
      font-size: 14px; }
    
    .respond-email-form a {
      font-size: 14px; }
  }


  
  .email-form-wrapper {
    padding: 30px 0;
    
    .vacancy__form {
      margin-top: 0;
    }
  }

  input, textarea, .ant-select-selector {
    border: 1px solid #8A8A89 !important;
  }
  
  
  @media(max-width: 990px) {
    .email-form-wrapper {
      padding: 15px 0;
    }
  }
`;

export const VacancySubscriptionMessage = styled.div`
  display: none;
  background: #F5F7FB;
  padding: 20px;
  font-size: 14px;  
  
  p {
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  
  a {
    display: inline-block;  
    margin-top: 10px;
    color: #EF6957;
    
    svg {
      margin-left: 5px;
    }
  }
  
  @media (max-width: 1140px) {
    display: block;
  }
`;

export const VacancyRight = styled.div`
  
`;

export const VacancyRightWrapper = styled.div`
  position: relative;
  width: 300px; 
  background: ${theme.colors.white};

  &.hide-vacancy-in-mobile {
    display: block;
  }

  &.show-vacancy-in-mobile {
    display: none;
  }
  
  .desktop-image {
    display: block;
  }
  
  .mobile-image {
    display: none;
  }
  
  .vertical-banner-desktop-cont {
    /* width: 100%; */
    width: 300px;
    height: 500px;
  }
  
  .vertical-banner-mob-cont {
    max-width: 100%;
  }
  
  @media (max-width: 1140px) {
    width: 100%;
    text-align: center;

    &.hide-vacancy-in-mobile {
      display: none;
    }
    
    &.show-vacancy-in-mobile {
      display: block;
    }
    
    .desktop-image {
      display: none;
    }
    
    .mobile-image {
      display: block;
    }
  }
  
  @media (max-width: 760px) {
    .vertical-banner-mob-cont {
      width: 100%;
    }
  }
`;


export const VacancyRightInfo = styled.div`
`;

export const VacancyBottomWrapper = styled.div`

  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 52px;

  svg path, svg rect {
    transition: .25s
  }

  @media (min-width: 991px) {
    a:hover svg path {
      fill:#EF6957
    }

    a:hover svg rect {
      fill: #fff
    }
  }

  .vacancy__bottom-left {
    margin-right: 25px
  }

  .vacancy__bottom-left,.vacancy__bottom-right,.vacancy__bottom-share {
    display: -ms-flexbox;
    display: flex
  }

  .vacancy__bottom-share {
    margin-right: 14px;
    -ms-flex-align: center;
    align-items: center;
    color: #8A8A89;
    position: relative
  }

  @media (min-width: 991px) {
    .vacancy__bottom-share:hover svg {
      fill:#EF6957
    }
  }

  .vacancy__bottom-share svg {
    fill: #8A8A89;
    margin-left: 18px;
  }

  .vacancy__bottom-share .ya-share2 {
    position: absolute;
    top: -4px;
    left: -16px
  }

  .vacancy__bottom-share .ya-share2__list.ya-share2__list_direction_horizontal>.ya-share2__item {
    width: 280px
  }

  .vacancy__bottom-share .ya-share2__container_size_m .ya-share2__item_more.ya-share2__item_has-pretty-view .ya-share2__link_more.ya-share2__link_more-button-type_short {
    opacity: 0
  }

  .vacancy__bottom-links,.vacancy__bottom-links a {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
  }

  .vacancy__bottom-links a {
    -ms-flex-pack: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    margin-left: 6px
  }

  .btn, .btn2 {
    height: 64px;
    font-size: 20px;
    line-height: 24px
  }

  .btn {
    width: 231px;
    margin-right: 32px
  }

  .btn2.orange-state, .btn2.favorite-vacancy {
    //margin-right: 32px;
    
    span {
      display: flex;
      align-items: center;
    }
  }

  @media (min-width: 1140px) {
    .btn2.orange-state, .btn2.favorite-vacancy {
      margin-right: 32px;
    }
  }

  .btn2.orange-state, .btn2.orange-state, .btn2.favorite-vacancy {
    color: #EF6957;
    background: #fff;
    border-color: #EF6957;
    
    .anticon {
      margin-left: 12px;
    }
  }

  .btn2.orange-state:hover, .btn2.orange-state:focus, .btn2.favorite-vacancy:hover {
    color: #fff;
    background: #EF6957;
  }

  .btn2.orange-state.disabled,
  .btn2.orange-state.disabled:hover,
  .btn2.orange-state.disabled:focus {
    color: #8A8A89;
    background: #fff;
    border-color: #8A8A89;
    cursor: not-allowed;
  }

  .btn2 {
    padding-left: 30px;
    padding-right: 30px
  }

  @media (max-width: 1440px) {
    display: block;

    .btn, .btn2 {
      height: 56px
    }

    .vacancy__bottom-right {
      margin-right: 0;
      margin-top: 20px
    }
  }

  @media (max-width: 1140px) {
    display: block;

    .btn, .btn2, .btn2.orange-state {
      margin-right: 8px;
    }

    .vacancy__bottom-right {
      margin-right: 0;
      margin-top: 20px
    }
  }

  @media (max-width: 990px) {
    margin-top: 32px;

    .btn, .btn2 {
      font-size: 14px;
      line-height: 16px;
      height: 48px
    }
  }

  @media (max-width: 575px) {
    .vacancy__bottom-left {
      display: block;
      margin-right: 0
    }

    .vacancy__bottom-left .btn, .vacancy__bottom-left .btn2 {
      width: 100%
    }

    .vacancy__bottom-left .btn2 {
      margin-top: 8px
    }

    svg {
      max-width: 20px;
      max-height: 18px
    }

    .vacancy__bottom-right {
      margin-top: 16px
    }

    .vacancy__bottom-share {
      margin-right: 8px
    }

    .vacancy__bottom-share a {
      font-size: 14px;
      line-height: 16px;
      display: -ms-flexbox;
      display: flex
    }

    .vacancy__bottom-share svg {
      width: 11px;
      height: 12px;
      margin-right: 10px;
      margin-left: 7px;
      position: relative;
      top: 2px
    }

    .vacancy__bottom-links a {
      margin-left: 0;
      margin-right: 4px;
      width: 32px
    }

    .vacancy__bottom-links a:last-of-type {
      margin-right: 0
    }
  }

  .vacancy__bottom-links a {
    cursor: pointer;
  }
`

export const VacancyNetworkWrapper = styled(VacancyBottomWrapper)`
  flex-direction: column;
  align-items: flex-start!important;
  margin-top: 0;
  margin-bottom: 32px;

  h4 {
    margin-bottom: 16px;
  }

  p {
    margin-top: 0;
    margin-bottom: 16px;
  }
  
  .btn {
    min-width: 323px;
  }

  @media (max-width: 990px) {
    margin-top: 28px;
    margin-bottom: 28px;

    .btn {
      min-width: 256px;
    }
  }

  @media (max-width: 575px) {

    .btn {
      min-width: auto;
    }
  }


  .btn.disabled-variant {
    background: #F9F9F9;
    border-color: #F9F9F9;
    color: #8A8A89;
    cursor: not-allowed;
  }
`

export const VacanciesBottomBannerWrapper = styled.div`
  .vacancy__vertical-banner-wrapper {
    margin-top: 27px;
    margin-bottom: 36px;
  }

  ${ReferalLink} {
    &.referal-link {
      margin-bottom: 25px
    }
  }
  
  /**
   * Надо именно через min-width, потому-что через max-with и
   * display: none; по умолчанию - не будут работать рекламные скрипты
   */
  @media (min-width: 1141px) {
    &.show-vacancy-banner-in-mobile {
      display: none;
    }
  }
`;

export const VacancyQuestion = styled.section`
  background: #F5F7FB;
  margin-top: 80px;

  @media (max-width: 760px) {
    .vacancy__question .container {
      padding: 0
    }
  }

  @media (max-width: 575px) {
    margin-top: 40px;
  }

`;

export const VacancyHorizontalBanner = styled.a`
  
  picture .desktop-image {
    width: 100%;
    display: block !important;
  }
  
  picture .mobile-image {
    width: 100%;
    display: none !important;
  }
  
  @media (max-width: 1140px) {
  
    picture .desktop-image {
      display: none !important;
    }
  
    picture .mobile-image {
      display: block !important;
    }
  }
`;

export const VacancyExpertBannerWrapper = styled.div`
  margin-top: 100px;
  background: #ff6e5a;
  display: flex;
  align-items: center; 
  justify-content: center;
`;

export const VacancyExpertBannerContent = styled.div`
  width: 100%;
  max-width: 1090px;
  height: 100%;
  color: white;
  padding: 62px 10px 10px;
  position: relative;
`;

export const VacancyExpertBannerSlide = styled.div`
  width: 100%; 
  height: 100%;
  display: flex;
  background: #ff6e5a;
  
  @media (max-width: 990px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const VacancyExpertBannerHeader = styled.div`
  position: absolute;
  width: auto;
  top: 64px;
  left: 460px;
  z-index: 9;

  h2 {
    color: ${theme.colors.white};
    font-size: 32px;
    font-weight: bold;
    line-height: 1.25;
    text-shadow: 0px 10px 15px rgba(48, 35, 33, 0.2);
  } 
  
  @media (max-width: 990px) {
    position: relative;
    top: 0;
    left: 0;
    
    h2 { 
      font-size: 22px;
    }
  }
`;

export const VacancyExpertBannerSlideLeft = styled.div`
  width: 100%;
  max-width: 420px;
  margin-right: 32px;
  z-index: 1;
   
  h2 {
    color: ${theme.colors.white};
    font-size: 32px;
    font-weight: bold;
    line-height: 1.25;
    text-shadow: 0px 10px 15px rgba(48, 35, 33, 0.2);
    display: none;
  }
  
  img {
    width: 100%;
  }
  
  @media (max-width: 990px) {
    display: flex; 
    flex-direction: column;
    max-width: 100%;
    margin: 0;
    
    h2 {
      display: block;
    }
    
    img {
      margin-top: 54px;
      max-width: 420px;
      align-self: center;
    }
  }
`;

export const VacancyExpertBannerSlideRight = styled.div`
`;

export const VacancyExpertBannerPositions = styled.div`
  padding-top: 32px;
  margin-top: 126px;

  @media (max-width: 990px) {
    margin-top: 0;
  }
`;

export const VacancyExpertBannerPositionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  
  img {
    width: 12px;
  }
  
  @media (max-width: 990px) {
    margin-bottom: 15px;
  }
`;

export const VacancyExpertBannerPositionCircle = styled.div`
  width: 10px; 
  border-radius: 50%;
  border: 3px solid white;
  background: ${ props => props.isActive ? theme.colors.white : 'transparent' };
  
  &:before {
    content: '';
    float: left;
    padding-top: 100%;
  }
  
  @media (max-width: 990px) {
    border: 2px solid white;
  }
`;

export const VacancyExpertBannerPositionTitle = styled.div`
  font-size: 12px;
  margin-left: 10px;
  line-height: 1.25;
  margin-top: -2px;
  text-shadow: 0px 5px 10px rgb(48 35 33 / 28%);
  
  @media (max-width: 990px) {
    font-size: 13px;
    margin-left: 8px;
  }
`;

export const VacancyExpertBannerControls = styled.div`
  position: absolute;
  width: auto;
  height: 58px;
  bottom: 24px;
  left: 462px;
  z-index: 9;
  display: flex;
  align-items: flex-end;
  
  @media (max-width: 990px) {
    position: relative;
    left: 0;
    bottom: 0;
    height: auto;
    margin-top: 48px;
    align-items: center; 
    justify-content: center;
    flex-direction: column;
  }
`;

export const VacancyExpertBannerControlButton = styled.div`
  width: 58px;
  border: 4px solid white;
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  align-items: center; 
  justify-content: center;
  transition: ${theme.animation.transition};
   
  &:before {
    content: '';
    float: left;
    padding-top: 100%;
  }
  
  &:hover {
    opacity: .8;
  }
  
  img {
    height: 18px;
  }
  
  @media (max-width: 990px) {
    display: none;
  }
`;

export const VacancyExpertBannerControlLink = styled.a`
  height: 100%;
  background: ${theme.colors.white};
  font-size: 14px;
  margin-right: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  text-transform: uppercase;
  color: #202549;
  font-weight: bold;
  transition: ${theme.animation.transition};
  text-shadow: 0px 10px 15px rgba(48, 35, 33, 0.2);
  
  
  &:hover {
    color: #202549;
    opacity: .8;
  }
  
  @media (max-width: 990px) {
    width: 100%; 
    max-width: 320px;
    margin: 0;
    height: 58px;
  }
`;

export const VacancyExpertBannerControlPage = styled.div`
  margin-left: 12px;
  line-height: 85%;
  text-shadow: 0px 10px 15px rgba(48, 35, 33, 0.2);
  
  span {
    font-family: 'TT Commons'; 
    font-size: 31px; 
    font-weight: 900;
  }
  
  sub {
    font-family: 'TT Commons';
    font-size: 12px;
    bottom: 0;
  }
  
  @media (max-width: 990px) {
    display: none;
  }
`;

export const VacancyExpertBannerControlMobilePage = styled.div`
  display: none;
  margin-top: 30px;
  margin-bottom: 20px;
  
  div {
    margin: 0 7.5px;
  }

  @media (max-width: 990px) {
    display: flex;
  }
`;

export const VacancyTitleWrapper = styled.span`
  color: white;
  padding: 4px 4px;
  text-align: left;
  line-height: 100%;
  background: #ef6957;
  box-decoration-break: clone;
`

export const VacancyHiddenPriceWrapper = styled.span`
  .vacancy-hidden-price {
    background-color: #ef6957;
    color: white;
    font-size: 21px;
    font-weight: normal;
    font-style: normal;
    padding: 3px 5px;
    transform: translateY(2px);
    display: inline-block;
    line-height: 100%;
    user-select: none;
    font-family: 'HelveticaNeueCyr';
  }
  
  &.vacancy-hidden-price-wrapper {
    line-height: 68px;
    
    &.slider-vacancies-banner {
      line-height: 52px;

      .vacancy-hidden-price{
        font-size: 20px;
        transform: translateY(3px);
        padding: 4px 5px;
      }
    }
    
    &.part-vacancies-banner {
      line-height: 32px;

      .vacancy-hidden-price {
        font-size: 15px;
        padding: 3.5px 5px;
        transform: translateY(1px);
      }
    }
  }

  @media (max-width: 1440px) {
    .vacancy-hidden-price {
      font-size: 19px;
      transform: translateY(2px);
    }
    
    &.vacancy-hidden-price-wrapper {
      line-height: 44px;
      
      &.slider-vacancies-banner {
        line-height: 40px;

        .vacancy-hidden-price {
          font-size: 16px;
          transform: translateY(3px);
        }
      }
      
      &.part-vacancies-banner {
        line-height: 29px;

        .vacancy-hidden-price {
          font-size: 13px;
          transform: translateY(0px);
        }
      }
    }
  }

  @media (max-width: 990px) {
    .vacancy-hidden-price {
      font-size: 14px;
      padding: 3px 3px;
      transform: translateY(0px);
    }
    
    &.vacancy-hidden-price-wrapper {
      line-height: 32px;
      
      &.part-vacancies-banner {
        line-height: 28px;

        .vacancy-hidden-price {
          font-size: 12px;
          padding: 2px 4px;
          transform: translateY(1px);
        }
      }

      &.slider-vacancies-banner {
        .vacancy-hidden-price {
          transform: translateY(1px);
          padding: 2px 4px;
        }
      }
    }
  }

  @media (max-width: 575px) {
    &.vacancy-hidden-price-wrapper {
      line-height: 28px;

      &.part-vacancies-banner {
        .vacancy-hidden-price {
          font-size: 12px;
          padding: 2px 2px;
          transform: translateY(1px);
        }
      }
      
      &.slider-vacancies-banner {
        line-height: 26px;

        .vacancy-hidden-price {
          font-size: 11px;
          transform: translateY(0px);
          padding: 2px 4px;
        }
      }
    }
  }
`;


export const VacancyHiddenPrice = styled.span`
  height: 27px;
  width: 132px;
  background-color: white;
  background-image: linear-gradient(
          45deg, #ef6957 25%, transparent 25%, transparent 74%, #ef6957 75%, #ef6957), linear-gradient(
          45deg,#ef6957 25%, transparent 25%, transparent 74%, #ef6957 75%, #ef6957);
  background-size: 6px 6px;
  background-position: 0 0, 3px 3px;
  transform: translateY(3px);
  display: inline-block;
  
  

  &.part-vacancies {
    height: 22px;
    width: 112px;
    transform: translateY(4px);
  }
  
  &.slider-vacancies {
    height: 28px;
    width: 112px;
    transform: translateY(4px);
  }

  &.hidden-email {
    height: 22px;
    width: 132px;
    transform: translateY(1px);
  }

  @media (max-width: 1440px) {
    height: 25px;
    transform: translateY(5px);

    &.part-vacancies {
      height: 20px;
      width: 102px;
      transform: translateY(4px);
    }

    &.slider-vacancies {
      height: 23px;
      width: 102px;
      transform: translateY(4px);
    }
    
  }
  
  @media (max-width: 990px) {
    height: 20px;
    width: 102px;
    transform: translateY(3px);

    &.part-vacancies {
      height: 15px;
      width: 82px;
    }

    &.slider-vacancies {
      height: 18px;
      width: 102px;
      transform: translateY(3px);
    }

    &.hidden-email {
      height: 15px;
      width: 112px;
      transform: translateY(4px);
    }
  }

  @media (max-width: 575px) {
    height: 20px;
    width: 102px;
    transform: translateY(3px);

    &.part-vacancies {
      height: 15px;
      width: 82px;
    }

    &.slider-vacancies {
      height: 15px;
      width: 102px;
      transform: translateY(3px);
    }
  }

`;
