import styled from "styled-components";

export const VacanciesSliderWrapper = styled.section`
  &.main-slider {
    padding-top: 100px;
    padding-bottom: 104px;
    overflow: hidden
  }

  .main-slider__wp {
    position: relative
  }

  .main-slider__top {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 28px;
    border-bottom: 1px solid rgba(138,138,137,.4)
  }

  .main-slider__top a {
    margin-top: 2px
  }

  .main-slider__slider {
    padding-top: 79px;
    padding-bottom: 73px;
    border-bottom: 1px solid rgba(138,138,137,.4);
    position: relative
  }

  .main-slider__slider-buttons {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    height: 1px;
    width: 120%;
    position: absolute;
    top: 57%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1
  }

  .main-slider__slider-buttons svg {
    line-height: 0
  }

  .main-slider__slider-buttons .swiper-button-disabled:hover svg,.main-slider__slider-buttons .swiper-button-disabled svg {
    fill: #8A8A89
  }

  .main-slider__slider-prev {
    transform: rotate(180deg)
  }

  .main-slider__slider-next,.main-slider__slider-prev {
    line-height: 0;
    cursor: pointer
  }

  .main-slider__slider-next svg,.main-slider__slider-prev svg {
    transition: .25s
  }

  .main-slider__slider-next:hover svg,.main-slider__slider-prev:hover svg {
    fill: #EF6957
  }

  @media (max-width: 1720px) {
    .main-slider__slider-buttons {
      width:112%
    }
  }

  @media (max-width: 1140px) {
    .main-slider__slider-buttons {
      width: 102%
    }
  }

  @media (max-width: 990px) {
    &.main-slider {
      padding-top: 78px;
      padding-bottom: 33px
    }

    .main-slider__slider {
      padding-top: 50px;
      padding-bottom: 64px
    }

    .main-slider__slider-next, .main-slider__slider-prev {
      margin: 0 20px
    }

    .main-slider__slider-next svg, .main-slider__slider-prev svg {
      width: 36px;
      height: 35px
    }

    .main-slider__slider-buttons {
      position: relative;
      top: -37px;
      left: auto;
      transform: none;
      -ms-flex-pack: center;
      justify-content: center;
      width: 100%
    }
  }

  @media (max-width: 760px) {
    &.main-slider {
      padding-top: 48px;
      padding-bottom: 47px;

      .link-arrow {
        font-size: 14px
      }
    }

    .main-slider__slider {
      padding-top: 17px;
      padding-bottom: 63px
    }

  }

  @media (max-width: 575px) {
    .main-slider__top {
      padding-bottom: 16px
    }

    &.main-slider .ya-share2__container_size_m.ya-share2__container_alone .ya-share2__popup_direction_bottom,
    &.main-slider .ya-share2__container_size_m .ya-share2__item_more.ya-share2__item_has-pretty-view .ya-share2__popup_direction_bottom {
      top: -100px
    }

    .main-slider__slider,
    .main-slider__slider .swiper-wrapper {
      overflow: visible;
      position: static
    }
  }
`;

export const VacanciesHeader = styled.div`
`;

export const VacanciesSlider = styled.div`
`;

export const VacanciesSliderButtons = styled.div`
`;

export const VacancySlide = styled.div`
  &.main-slider__slide {
    padding-left: 90px;
    padding-right: 103px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between
  }

  .main-slider__slide-title svg {
    fill: #8A8A89;
    margin-left: 5px;
    margin-top: 4px;
    margin-bottom: -5px;
    transition: fill .25s
  }

  .main-slider__slide-title button {
    display: inline;
    position: relative;
    background: transparent
  }

  .main-slider__slide-title button:hover svg {
    fill: #EF6957
  }

  .main-slider__slide-title .ya-share2__container_size_m.ya-share2__container_alone .ya-share2__popup_direction_bottom,
  .main-slider__slide-title .ya-share2__container_size_m .ya-share2__item_more.ya-share2__item_has-pretty-view .ya-share2__popup_direction_bottom {
    top: -117px
  }

  .main-slider__slide-title .ya-share2__container_size_m .ya-share2__item_more.ya-share2__item_has-pretty-view .ya-share2__link_more {
    opacity: 0
  }

  .main-slider__slide-title .ya-share2 {
    position: absolute;
    top: 0;
    left: 0
  }

  .main-slider__slide-title a:hover svg {
    fill: #EF6957
  }

  .main-slider__slide-title h2 {
    display: inline;
    border-bottom: 2px solid transparent;
    transition: border .25s
  }

  @media (min-width: 991px) {
    .main-slider__slide-title h2:hover {
      border-color:#EF6957
    }
  }

  &.main-slider__slide h5 {
    color: #8A8A89;
    margin-top: 24px
  }

  .main-slider__slide-left {
    max-width: 840px
  }

  .main-slider__slide-right {
    width: 327px;
    min-width: 327px;
    padding-top: 12px;
    margin-left: 20px
  }

  .main-slider__slide-right p {
    opacity: .8
  }

  .main-slider__slide-date {
    display: -ms-flexbox;
    display: flex;
    margin-top: 33px
  }

  .main-slider__slide-date .podp {
    margin-right: 33px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    color: #8A8A89
  }

  .main-slider__slide-date .podp svg {
    line-height: 0;
    margin-top: -2px;
    margin-right: 4px
  }


  @media (max-width: 1720px) {
    &.main-slider__slide {
      padding-left: 50px;
      padding-right: 50px
    }
  }



  @media (max-width: 990px) {
    &.main-slider__slide {
      display: block;
      padding-left: 0;
      padding-right: 0
    }

    .main-slider__slide-right {
      width: 100%;
      padding-top: 28px;
      margin-left: 0;
      min-width: 0
    }

    .main-slider__slide-date,.main-slider__slide h5 {
      margin-top: 12px
    }
  }


  @media (max-width: 575px) {
    &.main-slider__slide {
      margin-right: 20px;
      position: static
    }

    .main-slider__slide-title h2 {
      font-size: 16px;
      line-height: 22px
    }

    .main-slider__slide-title h2 a {
      display: none
    }

    .main-slider__slide-date {
      margin-top: 13px
    }

    .main-slider__slide-date .podp {
      font-size: 12px;
      line-height: 20px;
      margin-right: 0
    }

    .main-slider__slide-date .podp svg {
      width: 18px;
      height: 17px;
      margin-top: 4px
    }

    .main-slider__slide-date .podp:first-of-type {
      margin-right: 12px
    }

    .main-slider__slide-right {
      font-size: 12px;
      line-height: 20px
    }
  }


  .main-slider__slide-right__content {
    opacity: .8;
    max-height: 132px;
    overflow: hidden;
  }
  
  @media (max-width: 1440px) {
    .main-slider__slide-right__content {
      max-height: 95px;
    }
  }
  
  @media (max-width: 990px) {
    .main-slider__slide-right__content {
      max-height: 54px;
    }
  }
  
  @media (max-width: 480px) {
    .main-slider__slide-right__content {
      max-height: 100px;
    }
  }

  .main-slider__slide-right__ellipsis {
    opacity: .8;
  }
  
  @media (max-width: 990px) {
    .main-slider__slide-right__ellipsis {
      opacity: 1;
    }
  }
`;

export const VacancyHeader = styled.div`
`;

export const VacancyDateAndPlace = styled.div`
  .vacancy__top-date {
    margin-right: 28px
  }

  .vacancy__top-date,.vacancy__top-place {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #8A8A89;
    display: flex;
    align-items: center;
  }

  .vacancy__top-date svg,.vacancy__top-place svg {
    line-height: 0;
    margin-right: 4px;
    margin-top: -2px;
  }

  @media (max-width: 990px) {
    .vacancy__top-date,.vacancy__top-place {
      font-size: 12px;
      line-height: 20px;
      margin-right: 18px
    }
  }

  @media (max-width: 575px) {
    .vacancy__top-date, .vacancy__top-place {
      margin-right: 0;
      width: 49%
    }

    .vacancy__top-date svg, .vacancy__top-place svg {
      width: 16px;
      height: 20px;
      top: 6px
    }
  }

  &.vacancy__top-wrap-date-and-place {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 10px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }

  &.vacancy__top-wrap-date-and-place .btn {
    border-radius: 24px;
    height: 28px;
    padding: 1px 14px 0;
    margin-right: 10px;
    margin-top: 10px;
    font-size: 14px;
    line-height: 17px;
    min-width: 95px
  }

  &.vacancy__top-wrap-date-and-place .btn:last-of-type {
    margin-right: 0
  }

  &.vacancy__top-wrap-date-and-place .vacancy__top-date,
  &.vacancy__top-wrap-date-and-place .vacancy__top-place {
    margin-top: 10px;
    height: 25px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
  }

  &.vacancy__top-wrap-date-and-place .vacancy__top-date svg,
  &.vacancy__top-wrap-date-and-place .vacancy__top-place svg {
    top: 0;
    margin-top: -2px;
  }

  &.vacancy__top-wrap-date-and-place .vacancy__top-place {
    margin-right: 20px
  }

  @media (max-width: 990px) {
    &.vacancy__top-wrap-date-and-place {
      margin-top: 6px;

      .vacancy-list__item-date {
        margin-right: 13px
      }

      .vacancy-list__item-date svg {
        display: none
      }

      .vacancy-list__item-date,
      .vacancy-list__item-place {
        width: auto
      }

      .vacancy-list__item-date svg,
      .vacancy-list__item-place svg {
        top: 7px
      }
    }
    
    &.vacancy__top-wrap-date-and-place .btn {
      font-size:10px;
      line-height: 15px;
      height: 26px;
      margin-right: 8px;
      padding-left: 10px;
      padding-right: 10px
    }

    &.vacancy__top-wrap-date-and-place .vacancy__top-place {
      margin-right: 13px
    }
  }

  @media (max-width: 360px) {
    &.vacancy__top-wrap-date-and-place .vacancy__top-place {
      margin-right:0
    }
  }
`;

export const VacancyDescription = styled.div`
`;