import React, {useCallback, useEffect, useState} from 'react';
import {Tooltip} from "antd";
import {VacancyHiddenPrice, VacancyHiddenPriceWrapper} from "../Page/Vacancy/Vacancies.style";
import {parseCookies} from "nookies";
import {CustomTooltip} from "./CustomTooltip";

const VacancyPrice = ({ salary, fullVacancy, isFullPrice = true, isDetailVacancy = false, profile,
                        bannerClassName = '', hidePriceClassName = '' }) => {
  const cookies = parseCookies();
  const { vl_token } = cookies;

  const [currentSalary, setCurrentSalary] = useState(null);
  const [showFacancyData, setShowFacancyData] = useState(false);
  const [showEmployerData, setShowEmployerData] = useState(false);
  const [showVacancySalary, setShowVacancySalary] = useState(false);
  const [isOutdatedVacancy, setIsOutdatedVacancy] = useState(false);

  const ABOUT_VACANCY_TOOLTIP_DATA = (
    <span  className="tooltip-text-wrapper">
      <p>Данные Facancy - это размеры оплаты для соответствующих позиций, которые
      рассчитаны исходя из нескольких источников, в том числе:</p>
      <p className="tooltip-list-block">
        <p>- экспертных данных от ряда лучших HR-ов</p>
        <p>- накопленных данных из разных источников мониторинга рынка труда</p>
        <p>- данных от пользователей Facancy</p>
        <p>- других источников зарплатной аналитики</p>
      </p>
      <p>Данные Facancy являются исключительно справочными данными для соискателя и не
      являются офертой и/или реальными данными для конкретной опубликованной
      вакансии.</p>
      <p>Данные Facancy публикуются только если работодатель не указал предлагаемый им
      доход для конкретной опубликованной вакансии.</p>
    </span>
  )

  const VACANCY_TOOLTIP_BY_AUTH_USER = (
      <span className="tooltip-text-wrapper">
        <p>Данные Facancy доступны только тем пользователям Facancy, кто заполнил поле
        “Размер заработной платы” для своей текущей позиции не более полугода назад.</p>
        <p>Данные Facancy - это размеры оплаты для соответствующих позиций, которые
        рассчитаны исходя из нескольких источников, в том числе:</p>
        <p className="tooltip-list-block">
          <p>- экспертных данных от ряда лучших HR-ов</p>
          <p>- накопленных данных из разных источников мониторинга рынка труда</p>
          <p>- данных от пользователей Facancy</p>
          <p>- других источников зарплатной аналитики</p>
        </p>
        <p>Данные Facancy являются исключительно справочными данными для соискателя и не
        являются офертой и/или реальными данными для конкретной опубликованной
        вакансии.</p>
    </span>
  )

  const VACANCY_TOOLTIP_BY_NOT_AUTH_USER = (
    <span className="tooltip-text-wrapper">
      <p>Данные о размере оплаты доступны только авторизованным подписчикам Facancy. Если вы подписчик Facancy, но не видите конкретных данных (а вместо этого видите это предупреждение), то вам нужно авторизоваться на сайте. Это можно сделать, зайдя на Facancy по любой ссылке из вашего персонального еженедельного письма (например по ссылке на личный кабинет) на этом устройстве и в этом браузере (с которого вы сейчас просматриваете страницу).</p>
    </span>
  )

  useEffect(() => {
    setShowVacancySalary(!!profile?.can_see_vacancy_salary);
  }, [profile])

  useEffect(() => {
    if (fullVacancy) {
      setIsOutdatedVacancy(fullVacancy.is_outdated || fullVacancy.is_removed_from_publication);
    }
  }, [fullVacancy])

  useEffect(() => {
    setCurrentSalary({
      by_employer: {
        max: _getShortNumber(salary?.by_employer?.max || null),
        min: _getShortNumber(salary?.by_employer?.min || null)
      },
      by_facancy_data: {
        max: _getShortNumber(salary?.by_facancy_data?.max || null),
        median: _getShortNumber(salary?.by_facancy_data?.median || null),
        min: _getShortNumber(salary?.by_facancy_data?.min || null),
      },
      has_by_facancy_data: salary?.has_by_facancy_data,
      has_by_employer: salary?.has_by_employer,
    })

  }, [salary]);


  const _getShortNumber = (salarySize) => {
    if (!salarySize) {
      return null;
    }

    let size = null;
    const salarySizeLength = salarySize?.toString()?.length;
    if (salarySizeLength >= 4) {
      size = Math.floor(salarySize / 1000);
    } else {
      size = Math.floor(salarySize / parseInt(`1${('0'.repeat(salarySizeLength - 1))}`)) + '0'.repeat(salarySizeLength - 1)
    }

    if (isFullPrice && salarySizeLength >= 4) {
      if (size.toString().length >= 4) {
        size = size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "\xa0");
      }

      size = size.toString() + '\xa0000'
    }
    return size;
  }

  const getArea = useCallback(() => (
      <>
        <CustomTooltip placement="top" overlayClassName="vacancy-hidden-price-tooltip"
                 title={!vl_token ? VACANCY_TOOLTIP_BY_NOT_AUTH_USER : VACANCY_TOOLTIP_BY_AUTH_USER}>
          <VacancyHiddenPrice className={hidePriceClassName }/>
        </CustomTooltip>
      </>

  ), [vl_token]);

  const _getFormattedString = useCallback((value, type, section) => {
    if (!value) {
      return '';
    }
    return {
      before: `от\xa0${value}\xa0${salary[section]?.min?.toString()?.length > 4 && !isFullPrice ? 'тыс.\xa0' : ''}`,
      after: `до\xa0${value}\xa0${salary[section]?.max?.toString()?.length > 4 && !isFullPrice ? 'тыс.\xa0' : ''}р.`,
      median: `(в\xa0среднем\xa0${value}\xa0${salary[section]?.median?.toString()?.length > 4 && !isFullPrice ? 'тыс.\xa0' : ''}р.)`,
      single: `${value}\xa0${salary[section]?.min?.toString()?.length > 4 && !isFullPrice ? 'тыс.\xa0' : ''}р.`
    }[type]
  }, [salary, isFullPrice])

  if (!currentSalary) {
    return null;
  }

  return (
    <>
      {
        (currentSalary?.has_by_employer) ? (
          <>
            <VacancyHiddenPriceWrapper className={`vacancy-hidden-price-wrapper ${bannerClassName}`} style={{ fontWeight: !isDetailVacancy ? 400 : 'normal',
              paddingTop: isDetailVacancy ? '15px' : '11px',
              display: 'block' }}>
                <span style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
                  {!showVacancySalary ? (
                    <>
                      <span>{`от\xa0`}{getArea()}{`\xa0`}</span>
                      <span>{`до\xa0`}{getArea()}{`\xa0`}</span>
                    </>
                  ) : (
                    <>
                      {
                        currentSalary?.by_employer?.min === currentSalary?.by_employer?.max ?
                          _getFormattedString(currentSalary?.by_employer?.min, 'single', 'by_employer')
                          : (
                            <>
                              <span>{currentSalary?.by_employer?.min && `${_getFormattedString(currentSalary?.by_employer?.min, 'before', 'by_employer')}${(!currentSalary?.by_employer?.max) ? 'p.\xa0 ' : ''}`}</span>
                              <span>{currentSalary?.by_employer?.max && _getFormattedString(currentSalary?.by_employer?.max, 'after', 'by_employer')}</span>
                            </>
                          )
                      }
                    </>
                  )}
                </span>
              </VacancyHiddenPriceWrapper>
          </>
        ) : (
          <>
            {(isOutdatedVacancy) ? null : (
              <VacancyHiddenPriceWrapper className={`vacancy-hidden-price-wrapper ${bannerClassName}`} style={{
                      fontWeight: !isDetailVacancy ? 400 : 'normal',
                      paddingTop: isDetailVacancy ? '15px' : '11px',
                      display: 'block'
                    }}>
                <span style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>

                  {!showVacancySalary ? (
                    <>
                      {!!currentSalary.has_by_facancy_data && (
                        <>
                          <CustomTooltip placement="topLeft" overlayClassName="vacancy-hidden-price-tooltip" title={ABOUT_VACANCY_TOOLTIP_DATA}>
                            <span className={`vacancy-hidden-price`}>
                                    {'по данным Facancy'.toUpperCase()}</span>
                          </CustomTooltip>
                          {`\xa0`}
                          <span>{`от\xa0`}{getArea()}{`\xa0`}</span>
                          <span>{`до\xa0`}{getArea()}{`\xa0`}</span>
                          <span>{`(в\xa0среднем\xa0`}{getArea()}{`)`}</span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {!!(currentSalary?.by_facancy_data?.min || currentSalary?.by_facancy_data?.median || currentSalary?.by_facancy_data?.max) && (
                        <>
                          <CustomTooltip placement="topLeft" overlayClassName="vacancy-hidden-price-tooltip"
                                   title={ABOUT_VACANCY_TOOLTIP_DATA}>
                            <span className={`vacancy-hidden-price`}>
                                    {'по данным Facancy'.toUpperCase()}</span>
                          </CustomTooltip>
                          {`\xa0`}
                        </>
                      )}

                      {currentSalary?.by_facancy_data?.min === currentSalary?.by_facancy_data?.median === currentSalary?.by_facancy_data?.max ? (
                        `${_getFormattedString(currentSalary?.by_facancy_data?.min, 'single', 'by_facancy_data')}`
                      ) : (
                        <>
                          <span>{currentSalary?.by_facancy_data?.min && `${_getFormattedString(currentSalary?.by_facancy_data?.min, 'before', 'by_facancy_data')}${(!currentSalary?.by_facancy_data?.max) ? 'p.\xa0' : ''}`}</span>
                          <span>{currentSalary?.by_facancy_data?.max && `${_getFormattedString(currentSalary?.by_facancy_data?.max, 'after', 'by_facancy_data')}${currentSalary?.by_facancy_data?.median ? '\xa0' : ''}`}</span>
                          <span>{currentSalary?.by_facancy_data?.median && `${_getFormattedString(currentSalary?.by_facancy_data?.median, 'median', 'by_facancy_data')}`}</span>
                        </>
                      )}
                    </>
                  )}
                </span>
              </VacancyHiddenPriceWrapper>
            )}
          </>
        )
      }
    </>
  )
}

export default VacancyPrice;